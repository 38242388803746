<template>
  <div class="page-index">
    <top-bar />

    <div class="banners">
      <c-banners :data="banners" :loading="$var('loadBanners')" />
    </div>

    <div class="container">
      <h3>Категории товаров</h3>
    </div>
    <categories-list />

    <div class="container">
      <h3>Топ товаров</h3>
    </div>
    <c-products-list-top :data="topProducts" :loading="$var('loadTop')" />

    <div class="container">
      <h3>Витрина товаров</h3>
    </div>
    <div class="container">
      <c-products-list :data="products" :loading="$var('load')" />
    </div>
  </div>
</template>

<script>
import CategoriesList from './components/CategoriesList'

export default {
  name: 'PageIndex',
  components: {
    CategoriesList,
  },
  data() {
    return {
      banners: [],
      products: [],
      topProducts: [],
    }
  },
  created() {
    this.loadBanners()
    this.load()
    this.loadTop()
  },
  methods: {
    loadBanners() {
      this.$var('loadBanners', true)
      $api.app.banners.get().then((response) => {
        this.banners = response.data.content
      }).finally(() => {
        this.$var('loadBanners', false)
      })
    },
    load() {
      this.$var('load', true)
      $api.app.products.get().filter({ onlyStores: true, }).size(50).sort('createdAt', 'desc').then((response) => {
        this.products = response.data.content
      }).finally(() => {
        this.$var('load', false)
      })
    },
    loadTop() {
      this.$var('loadTop', true)
      $api.app.products.get().filter({ onlyStores: true, }).sort('salesCount', 'desc').size(10).then((response) => {
        this.topProducts = response.data.content
      }).finally(() => {
        this.$var('loadTop', false)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.page-index {
  &::v-deep .swiper-slide{
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  }

  &::v-deep .n-slider{
    padding-bottom: 0;
  }

  &::v-deep .swiper-container{
    padding-bottom: 20px;
  }
}
</style>
