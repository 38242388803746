<template>
  <div class="categories-list">
    <n-loader :loading="$var('load')"></n-loader>
    <ul>
      <li v-for="(item, index) in categories" :key="index">
        <n-link v-if="isParent = true" :to="{name: 'categories.sub', params: {id: item.id}}">
          <div class="content">
            <div class="img">
              <img :src="item.avatar" alt="">
            </div>
            <h5>{{ item.title }}</h5>
          </div>
        </n-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'CategoriesList',
  data() {
    return {
      categories: [],
      parent: {},
    }
  },
  created() {
    this.load()
  },
  methods: {
    load() {
      this.$var('load', true)
      $api.app.categories.get().filter({ isParent: true, }).then((response) => {
        this.categories = response.data.content
      }).finally(() => {
        this.$var('load', false)
      })
    },
  },
}
</script>

<style scoped lang="scss">
.categories-list {
  overflow: auto;
  padding-bottom: 12px;
  margin: 0 0 14px 20px;
  position: relative;
  min-height: 210px;

  &::-webkit-scrollbar {
    height: 5px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: var(--secondary);
    border-radius: 10px;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    width: 480px;
    position: relative;
    right: 5px;

    li {
      padding: 0 8px;

      &:first-child {
        .img {
          background: rgba(75, 178, 34, 0.67);
        }

        h5 {
          color: #4BB222;
        }
      }
    }
  }

  .content {
    width: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .img {
    margin: 0 auto 6px;
    width: 66px;
    height: 66px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    padding: 12px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  h5 {
    text-align: center;
  }
}
</style>
